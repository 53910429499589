import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Portal = () => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [reason, setReason] = useState("");
  const [fullDay, setFullDay] = useState(true);
  const [category, setCategory] = useState("");
  const [user, setUser] = useState({});
  const [remain, setRemain] = useState('');
  const [doc, setDoc] = useState([]);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    setUser(user);
    // fetchLeave();
  }, []);

  const fetchLeave = async () => {
    const response = await fetch("https://backend.atpldhaka.com/api/calculate-leave-days", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      const data = await response.json();
      setRemain(data);
    } else {
      throw new Error("Request failed"); // Handle errors here
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files;
    setDoc([...doc, ...file]);
  };

  const handleSubmit = async () => {
    const timeDiff = new Date(to) - new Date(from);
    const days = timeDiff / (1000 * 60 * 60 * 24) + 1;
    const formData = new FormData();
    formData.append("start_date", from);
    formData.append("end_date", to);
    formData.append("days", days);
    formData.append("reason_category", category);
    formData.append("reason", reason);
    doc.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    
    const response = await fetch("https://backend.atpldhaka.com/api/leave", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData,
    })

    if (response.ok) {
      toast.success("Application send succesfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      throw new Error("Request failed"); // Handle errors here
    }
  }

  return (
    <div className="min-h-screen bg-gray-100 p-10 space-y-5">
      <p className="text-3xl font-semibold">Leave Request:</p>
      <p className="bg-green-400 font-medium w-fit px-10 py-2 rounded text-white">Remaining leaves: {remain.remaining_days}</p>
      <p className="font-medium text-lg pt-10">Apply for leave</p>
      <div className="flex gap-10">
        <div className="flex gap-4 w-60">
          <input
            onChange={() => setFullDay(!fullDay)}
            checked={fullDay}
            type="checkbox"
          />
          <p>Full-day</p>
        </div>
        <div className="flex gap-4 w-60">
          <input
            onChange={() => setFullDay(!fullDay)}
            checked={!fullDay}
            type="checkbox"
          />
          <p>Half-day</p>
        </div>
      </div>
      <div className="flex gap-10">
        <div className="space-y-5">
          <div className="w-60">
            <p className="font-semibold w-60 pb-4">{fullDay ? "From" : "On"}</p>
            <input
              onChange={(e) => setFrom(e.target.value)}
              className="bg-gray-200 p-1 w-60"
              type="date"
            />
          </div>
          {fullDay && (
            <div className="w-60">
              <p className="font-semibold w-60 pb-4">To</p>
              <input
                onChange={(e) => setTo(e.target.value)}
                className="bg-gray-200 p-1 w-60"
                type="date"
              />
            </div>
          )}
        </div>
        <div>
          <p className="font-semibold w-60 pb-4">Reason</p>
          <textarea
            onChange={(e) => setReason(e.target.value)}
            className="bg-gray-200 p-1 w-60 h-32 max-h-40"
            type="text"
          />
        </div>
      </div>
      <div className="flex gap-10 items-center">
        <p className="font-semibold w-60">Select Category:</p>
        <select className="bg-gray-200 p-1 w-60" onChange={e => setCategory(e.target.value)}>
          <option
            value=""
          ></option>
          <option value="sick">
            Sick
          </option>
          <option value="casual">
            Casual
          </option>
          <option
            value="maternity"
          >
            Maternity
          </option>
          <option value="unpaid">
            Unpaid
          </option>
        </select>
      </div>
      <div className="flex gap-10">
        <p className="font-semibold w-60">Attatch Document :</p>
        <input type="file" onChange={handleFileChange} multiple />
      </div>
      <button
        onClick={handleSubmit}
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
      >
        Submit
      </button>
      <ToastContainer />
    </div>
  );
};

export default Portal;
