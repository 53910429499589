import React from 'react';

const Footer = () => {
    return (
        <div className='text-center bg-black bg-opacity-80 py-2 text-white z-10 relative'>
            &copy; 2023 ATPL Dhaka. All Rights Reserved.
        </div>
    );
}

export default Footer;
