import React, { useState, useEffect } from "react";

const Attendence = () => {
  const [data, setData] = useState([]);
  const [pickDate, setPickDate] = useState("");
  const [SelectedDateData, setSelectedDateData] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://zkteco.atpldhaka.com/get-auth-token"
      );
      const data = await response.json();
      const currentDate = new Date().toISOString().split("T")[0];
      setData(data);
      extractDatewiseData(data, currentDate);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const extractDatewiseData = (data, pickDate) => {
    setPickDate(pickDate);
    const PickDateData = data.result.filter((item) => item.date === pickDate);
    setSelectedDateData(PickDateData);
  };

  const calculateWorkingHours = (entryTime, leaveTime) => {
    const entryDate = new Date(entryTime);
    const leaveDate = new Date(leaveTime);

    // Calculate the time difference in milliseconds
    const timeDifference = leaveDate - entryDate;

    // Convert the time difference to hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    return hoursDifference.toFixed(2);
  };

  return (
    <div className="p-10 space-y-5">
      <h1 className="pb-4 font-semibold text-lg">Attendence List : {pickDate}</h1>
      <table className="border-collapse border border-green-800">
        <thead>
          <tr>
            <th className="border border-green-800 py-1 px-2 w-40">Date</th>
            <th className="border border-green-800 py-1 px-2 w-20">ID</th>
            <th className="border border-green-800 py-1 px-2 w-40">Name</th>
            <th className="border border-green-800 py-1 px-2 w-32">
              Entry Time
            </th>
            <th className="border border-green-800 py-1 px-2 w-32">
              Leave Time
            </th>
            <th className="border border-green-800 py-1 px-2 w-32">
              Working Hour
            </th>
          </tr>
        </thead>
        {SelectedDateData && (
          <tbody>
            {SelectedDateData?.map((item) => (
              <tr key={item.emp_code} className="text-center">
                <td className="border border-green-800 py-1 px-2">
                  {item.date}
                </td>
                <td className="border border-green-800 py-1 px-2">
                  {item.emp_code}
                </td>
                <td className="border border-green-800 py-1 px-2">
                  {item.first_name}
                </td>
                <td className="border border-green-800 py-1 px-2">
                  {item.first_id.punch_time.split(" ")[1]}
                </td>
                <td className="border border-green-800 py-1 px-2">
                  {item.last_id.punch_time.split(" ")[1]}
                </td>
                <td className="border border-green-800 py-1 px-2">
                  {calculateWorkingHours(
                    item.first_id.punch_time,
                    item.last_id.punch_time
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <div className="flex gap-5 items-center">
      <p>Select date</p>
      <input
        onChange={(e) => extractDatewiseData(data, e.target.value)}
        className="bg-gray-200 p-1 w-60"
        type="date"/>
      {/* <button className="bg-indigo-950 py-1 px-2 rounded text-white" onClick={e => console.log(e.target.value)}>View Details</button> */}
      </div>
    </div>
  );
};

export default Attendence;
