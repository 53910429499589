import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reminder, setReminder] = useState(false);
  const [error, setError] = useState("");
  const [loginForm, setLoginForm] = useState(true);
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSignIn = async () => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      const res = await fetch("https://backend.atpldhaka.com/api/auth/login", {
        method: "POST",
        body: formData,
      });

      const data = await res.json();
      if (data?.errors || data?.error) {
        if (data?.error) {
          setError(data.error);
          return;
        } else if (data?.errors) {
          setError(data.errors);
          return;
        }
      } else {
        sessionStorage.setItem("token", data.access_token);
        sessionStorage.setItem("user", JSON.stringify(data.user));
        navigate("/portal");
      }
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  // const handleSignUp = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("name", name);
  //     formData.append("email", email);
  //     formData.append("password", password);
  //     formData.append("password_confirmation", confirmPassword);

  //     const res = await fetch(
  //       "https://backend.atpldhaka.com/api/auth/register",
  //       {
  //         method: "POST",
  //         body: formData,
  //       }
  //     );
  //     const data = await res.json();
  //     console.log(data);
  //     if (data?.errors) {
  //       setError(data.errors);
  //       return;
  //     } else {
  //       notify(data.message);
  //       setLoginForm(true);
  //       setName("");
  //       setEmail("");
  //       setPassword("");
  //       setConfirmPassword("");
  //       setError("");
  //     }
  //   } catch (err) {
  //     console.log("Error");
  //     setError(err);
  //   }
  // };

  const notify = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen xl:py-2 bg-gray-100">
      <main className="flex flex-col items-center justify-center w-full flex-1 xl:px-20 text-center">
        {/* Sign in section */}
        <div className="bg-white rounded-2xl shadow-2xl md:flex xl:w-2/3 max-w-4xl">
          {/* {loginForm ? ( */}
            <div className="xl:w-3/5 p-5 md:shrink-0 flex flex-col items-center justify-center">
              <div className="text-2xl font-bold text-[#1a1633] text-center mt-4">
                ATPL Dhaka
              </div>
              <div className="py-10 text-[#1a1633] space-y-5">
                <h2 className="text-3xl font-bold mb-2">Sign in to Account</h2>
                <div className="border-2 w-10 border-[#1a1633] inline-block mb-2 "></div>
                <div className="flex flex-col items-center">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email"
                    className="text-sm bg-gray-100 outline-none w-64 p-2 mb-3"
                  />
                  <p className="text-xs text-red-400 py-1 -mt-3">
                    {error?.email?.[0]}
                  </p>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') { handleSignIn() }}}
                    type="password"
                    placeholder="Password"
                    className="text-sm bg-gray-100 outline-none w-64 p-2 mb-3"
                  />
                  <p className="text-xs text-red-400 py-1 -mt-3">
                  {error?.password?.[0] || error}
                  </p>
                  {/* <div className=" flex w-64 mb-5 justify-between">
                    <label htmlFor="#" className="text-xs flex items-center">
                      <input
                        onChange={(e) => setReminder(e.target.checked)}
                        type="checkbox"
                        name="remember"
                        className="mr-1"
                      />
                      Remember me
                    </label>
                    <a href="#" className="text-xs">
                    <p>Forgot Password?</p>
                  </a>
                  </div> */}
                  <button
                    onClick={handleSignIn}
                    className="border-2 border-[#1a1633] rounded-full px-12 py-2 inline-block font-semibold hover:bg-[#1a1633] hover:text-gray-100 mt-2"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          {/* ) : (
            <div className="xl:w-3/5 p-5 md:shrink-0 flex flex-col items-center justify-center">
              <div className="text-2xl font-bold text-[#1a1633] text-center mt-4">
                ATPL Dhaka
              </div>
              <div className="py-10 text-[#1a1633] space-y-5">
                <h2 className="text-3xl font-bold mb-2">Register an Account</h2>
                <div className="border-2 w-10 border-[#1a1633] inline-block mb-2 "></div>
                <div className="flex flex-col items-center">
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="name"
                    placeholder="Name"
                    className="text-sm bg-gray-100 outline-none w-64 p-2 mb-3"
                  />
                  <p className="text-xs text-red-400 py-1 -mt-3">
                    {error?.name?.[0]}
                  </p>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email"
                    className="text-sm bg-gray-100 outline-none w-64 p-2 mb-3"
                  />
                  <p className="text-xs text-red-400 py-1 -mt-3">
                    {error?.email?.[0]}
                  </p>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password"
                    className="text-sm bg-gray-100 outline-none w-64 p-2 mb-3"
                  />
                  <p className="text-xs text-red-400 py-1 -mt-3">
                    {error?.password?.length > 1
                      ? error?.password?.[1]
                      : error?.password?.[0]}
                  </p>
                  <input
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                    placeholder="Confirm Password"
                    className="text-sm bg-gray-100 outline-none w-64 p-2 mb-3"
                  />
                  <p className="text-xs text-red-400 py-1 -mt-3">
                    {error?.password?.length > 1 ? error?.password?.[0] : ""}
                  </p>
                  <button
                    onClick={handleSignUp}
                    className="border-2 border-[#1a1633] rounded-full px-12 py-2 inline-block font-semibold hover:bg-[#1a1633] hover:text-gray-100 mt-2"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          )} */}
          {/* sing up section */}
          <div className="xl:w-2/5 bg-[#1a1633] space-y-5 text-gray-100 rounded-r-2xl py-28 px-12">
            <h2 className="text-3xl font-bold mb-2">Hello !</h2>
            <div className="border-2 w-10 border-white inline-block mb-2 "></div>
            <p className="mb-2">
              Welcome to ATPL Dhaka Website. Please login to your account to get started.
            </p>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};

export default Login;
