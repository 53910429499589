import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Career from "./components/Career";
import Footer from "./components/Footer";
import Login from "./components/Login";
import { Routes, Route } from "react-router-dom";
import Portal from "./components/Portal";
import Attendence from "./components/Attendence";
import PrivateRoutesWithLayout from "./components/PrivateRoutesWithLayout";

function App() {
  return (
    <div className="w-full overflow-hidden relative dark:bg-gray-900">
      
      <Routes>
        <Route
          path="/"
          element={
            <>
            <Navbar />
              <Hero />
              <About />
              <Services />
              <Career />
              <Contact />
            </>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutesWithLayout />}>
          <Route path="/portal" element={<Portal />} />
          <Route path="/attendence" element={<Attendence />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
